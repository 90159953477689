<template>
  <main class="tourism fs16">
    <div class="d-flex align-items-center justify-content-center weight-700 my5">
      <p class="m-rl-20">Barcelona</p>
      <p>14/04/21</p>
      <img src="/assets/img/icons/flight.svg" alt="" class="mx10">
      <p>18/04/21</p>
    </div>
    <MobileBannerCarousel></MobileBannerCarousel>
    <div class="bg-white p-relative p5">
      <img src="/assets/img/icons/share-alt-light-black.svg" alt="" class="share-icon">
      <p class="text-center">
        <b>Flight details</b>
      </p>
      <MobileFlightDesData class="p-b-10 bd-bottom">
        <template slot="additionalData">
          <img src="/assets/img/icons/flight connection.svg" alt="">
          <p>5h 40m</p>
        </template>
      </MobileFlightDesData>
      <MobileFlightDesData class="p-b-10 m-t-15">
        <template slot="additionalData">
          <img src="/assets/img/icons/flightDirect.svg" alt="">
          <p>4h 40m</p>
        </template>
      </MobileFlightDesData>
      <div class="df-c m-t-15 m-b-5">
        <button class="primary-btn bg-blue5 small-btn color-primary" @click="openDetailModal">
          <img src="/assets/img/icons/info.svg" alt="" class="mx10">
          {{ $t( "TOURISM.FULL_DETAILS" ) }}
        </button>
      </div>
    </div>
    <div class="p5 d-flex fs14">
      <div class="bd-radius bdbr-1 df-c m-5 p5 bg-white weight-700 w-100">
        Baggage info
      </div>
      <div class="bd-radius bdbr-1 df-c m-5 p5 bg-white weight-700 w-100">
        General info
      </div>
      <div class="bd-radius bdbr-1 df-c m-5 p5 bg-white weight-700 w-100">
        <img src="/assets/img/icons/info.svg" alt="" class="m-rl-10">
        <p>COVID-19</p>
      </div>
    </div>
    <div class="bg-white text-center py10" v-if="!isOpenPassportBlock">
      3 Passengers: Add Passport details to continue
    </div>
    <div class="bg-white" v-else>
      <div class="d-flex justify-content-between align-items-center py5">
        <p><b>Passengers Passport details</b></p>
        <img src="/assets/img/icons/times-light-black.svg" alt="">
      </div>
      <div class="d-flex justify-content-between flex-wrap passports bg-grey4">
        <TourPassport class="p5"></TourPassport>
        <TourPassport class="p5"></TourPassport>
        <TourPassport class="p5"></TourPassport>
      </div>
    </div>
    <div class="p5 m-t-10">
      <FlightResult :isShowTitle="true"></FlightResult>
    </div>
    <div class="d-flex align-items-center mx20">
      <v-checkbox
        color="#0D3856"
        class="cus-checkbox"
      ></v-checkbox>
      <label class="px10 fs16 color-primary">
        I agree to the <span class="color-blue11 text-decoration-underline">order terms</span>
      </label>
    </div>
    <button class="primary-btn mobile-result-btn bg-green6" @click="openPassportBlock()" v-if="isOpenPassportBlock">
      Approve Order
    </button>
    <button class="primary-btn mobile-result-btn" @click="openPassportBlock()" v-else>
      Add Passport details
    </button>
    <p class="text-center color-red2 m-t-10">
      {{ $t( "COMMON.CANCEL" ) }}
    </p>
    <MobileDetailModal></MobileDetailModal>
  </main>
</template>

<script>
import MobileFlightDesData from "../../../../components/tourism/MobileFlightDesData.vue";
import FlightResult from "../../../../components/tourism/flight/FlightResult.vue";
import MobileDetailModal from "../../../../components/tourism/flight/MobileDetailModal.vue";
import TourPassport from "../../../../components/tourism/TourPassport.vue";
import MobileBannerCarousel from "../../../../components/tourism/MobileBannerCarousel.vue";
export default {
  name: "MobileFlight",
  components: {
    MobileFlightDesData,
    FlightResult,
    MobileDetailModal,
    TourPassport,
    MobileBannerCarousel
  },
  data() {
    return {
      settings: {
        "dots": true,
        "dotsClass": "slick-dots custom-dot-class",
        "edgeFriction": 0.35,
        "infinite": false,
        "speed": 500,
        "slidesToShow": 1,
        "slidesToScroll": 1
      },
      isOpenPassportBlock: false
    }
  },
  methods: {
    openDetailModal() {
      this.$modal.show("MobileDetailModal");
    },
    openPassportBlock() {
      this.isOpenPassportBlock = true;
    }
  }
}
</script>

<style lang="scss">
@import "../../../../assets/scss/tourism.scss";

</style>

<style lang="scss" scoped>
.mobile-result-btn {
  width: 80%;
  margin: 0 auto;
  margin-top: 10px;
}
.passports {
  padding: 10px 15px;
}

.share-icon {
  position: absolute;
  right: 10px;
  top: 5px;
  width: 18px;
}
</style>